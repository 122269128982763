<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/receiveNotice' }">消息通知</el-breadcrumb-item>
          <el-breadcrumb-item>编辑通知</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="规则名称" prop="title">
                <el-input v-model="form.title" placeholder="请输入规则名称" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="规则内容" prop="content">
                <el-input type="textarea" v-model="form.content" placeholder="请输入规则内容" maxlength="80" show-word-limit rows="4"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="规则类型" prop="noticeTypeCode">
                <el-select v-model="form.noticeTypeCode" placeholder="请选择规则类型" style="width:500px;">
                  <el-option
                    v-for="item in typeRules"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 告警级别 -->
          <!-- <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="告警级别" prop="level">
                <el-radio-group v-model="form.level" style="width:100%;">
                  <el-radio :label="1">提示告警</el-radio>
                  <el-radio :label="2">普通告警</el-radio>
                  <el-radio :label="3">重要告警</el-radio>
                  <el-radio :label="4">严重告警</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row> -->

          <!-- 通知频次 -->
          <el-row :gutter="20" v-if="form.noticeTypeCode=='equipment'">
            <el-col :span="12">
              <el-form-item label="通知频次" prop="frequencyType" style="position:relative;width: 600px;">
                <el-select v-model="form.frequencyType" style="width: 500px;margin-right:60px;" placeholder="请选择通知频次">
                  <el-option v-for="item in frequency" :key="item.code" :label="item.name" :value="item.code"></el-option>
                </el-select>
                <el-tooltip class="item" effect="dark" :content="computedContent(form.frequencyType)" placement="top">
                  <i class="el-icon-info info-tips"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 频次时间 -->
          <el-row :gutter="20" v-if="form.frequencyType&&form.frequencyType!='every-hour'">
            <el-col :span="12">
              <el-form-item label="频次时间" prop="dateTime">
                <el-time-select v-model="form.dateTime" style="width: 500px;margin-right:45px;" placeholder="选择频次时间" :picker-options="{ start: '00:05', step: '00:05', end: '23:55' }"></el-time-select>
                <!-- <el-time-picker v-model="form.dateTime" style="width: 500px;margin-right:45px;" placeholder="选择频次时间" value-format="HH:mm:ss" :picker-options="{ selectableRange: '00:00:00 - 23:59:59' }" ></el-time-picker> -->
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 统计方式 -->
          <el-row :gutter="20" v-if="form.noticeTypeCode=='equipment'">
            <el-col :span="12">
              <el-form-item label="统计方式" prop="statisticsType">
                <el-select v-model="form.statisticsType" style="width: 500px;margin-right:60px;" placeholder="请选择统计方式">
                  <el-option v-for="item in notifiMethod" :key="item.code" :label="item.name" :value="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 通知人 -->
          <!-- <el-row :gutter="20" v-if="form.noticeTypeCode!='equipment'">
            <el-col :span="12">
              <el-form-item label="通知人">
                <div @click="addStaff()" class="staffBox">
                  <div v-if="staffList.length>0">
                    <el-tag v-for="staff in staffList" :key="staff.id" style="margin-right: 4px;">
                      {{ staff.name }}
                    </el-tag>
                  </div>
                  <div v-else style="color: #909399;text-align: center;">请选择通知人员</div>
                </div>
              </el-form-item>
            </el-col>
          </el-row> -->

          <!-- 通知方式 -->
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="通知方式" prop="notificationModeCodes">
                <div class="notify-method">
                  <el-checkbox-group v-model="form.notificationModeCodes">
                    <!-- <el-checkbox label="system">系统内通知</el-checkbox> -->
                    <el-checkbox label="entWeChatRobot">企业微信</el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 选择机器人 -->
          <div v-if="form.notificationModeCodes.find(item=>item=='entWeChatRobot')=='entWeChatRobot'">
            <robot @onRobots="onRobot" :robotId="form.robotId" :robotName="form.robotName" :resource="'edit'"></robot>
          </div>

          <!-- 设备类型 -->
          <div v-if="form.noticeTypeCode=='equipment'">
            <configuration :equipmentss="equipments" :resource="'edit'"></configuration>
          </div>
          
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/receiveNotice')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
      <el-drawer
        title=""
        :with-header="false"
        :visible.sync="drawer"
        direction="rtl"
        size="1000px">
        <div class="drawer-title">选择通知人员</div>
        <div class="drawer-content" v-if="drawer">
          <div class="table-tree">
            <div class="tree">
              <div class="tree-title"><i class="el-icon-share"></i> 结构</div>
              <el-tree
                :data="treeData"
                @node-click="treeRowClick"
                :props="defaultProps"
                :expand-on-click-node="false"
                default-expand-all
                ref="tree">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
                </span>
              </el-tree>
            </div>
            
            <div class="table">
              <el-table :data="tableData" style="width: 100%" height="700">
                <el-table-column width="40">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.check" :checked="staffListID.includes(scope.row.id)" @change="checked(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="sex" label="性别">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-else>女</span>
                  </template>
                </el-table-column>
                <el-table-column prop="departmentName" label="部门"></el-table-column>
                <el-table-column prop="name" label="岗位">
                  <template slot-scope="scope">
                    <div
                      v-for="item in scope.row.positionInfoList"
                      :key="item.positionId"
                    >{{item.positionName}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="电话"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="drawer=false">取消</el-button>
          <el-button size="small" type="primary" @click="drawerOK" class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import layTime from '@/components/layTime'
import configuration from './configuration'
import robot from './robot'
import { contains } from '@antv/x6/lib/util/dom/elem';

export default {
  components: {
    layTime,
    configuration,
    robot
  },
  data() {
    return {
      loading: false,
      form: {
        level: 1,
        title: '',
        content: '',
        noticeTypeCode: null,
        notificationModeCodes: [],
        frequencyType: null,
        dateTime: null,
        statisticsType: null
      },
      row: {},
      typeRules: [
        // {code: 'operationTaskRuleExpiration',name: '巡点检规则过期告警'},
        // {code: 'lubeTaskRuleExpiration',name: '润滑规则过期告警'},
        {code: 'equipment',name: '设备类型'},
      ],
      rules: {
        title: [{ required: true, message: "必填项", trigger: "blur" }],
        content: [{ required: true, message: "必填项", trigger: "blur" }],
        noticeTypeCode: [{ required: true, message: "必填项", trigger: "change" }],
        level: [{ required: true, message: "必填项", trigger: "change" }],
        notificationModeCodes: [{ required: true, message: "必填项", trigger: "change" }],
        frequencyType: [{ required: true, message: "必填项", trigger: "change" }],
        dateTime: [{ required: true, message: "必填项", trigger: "change" }],
        statisticsType: [{ required: true, message: "必填项", trigger: "change" }],
      },
      frequency: [
        { code: 'every-hour', name: '每小时' },
        { code: 'daily', name: '每日' },
        { code: 'weekly', name: '每周' },
        { code: 'monthly', name: '每月' },
      ],
      notifiMethod: [
        { code: 'latest', name: '实时' },
        { code: 'daily', name: '单日' },
        { code: 'weekly', name: '每周' },
        { code: 'monthly', name: '每月' },
      ],
      treeData: [],
      tableData: [],
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },
      drawer: false,
      staffList: [], // 通知人员列表
      staffListID: [],
      checkedStaffList: [],
      
      deleteStaffIds: [],
      equipments: []
    };
  },
  computed: {
    computedContent() {
      return function(e){
        if(!e) return '请选择通知频次'   
        if(e=='every-hour') return '通知频次为: 按小时为单位自动发送通知'
        if(e=='daily') return '通知频次为: 按天为单位自动发送通知'
        if(e=='weekly') return '通知频次为: 按周的星期一自动发送通知'
        if(e=='monthly') return '通知频次为: 按月的1号自动发送通知'
      }
    }
  },
  methods: {
    onRobot(val) {
      this.form.robotId = val.robotId
      this.form.robotName = val.robotName
    },
    // 选择通知人员
    addStaff() {
      this.loadTableData(this.treeData[0].id)
      this.staffListID = []
      this.staffList.forEach(item => {
        this.staffListID.push(item.id)
      })
      this.checkedStaffList = [].concat(this.staffList)
      this.drawer = true
    },
    // 获取部门结构树
    loadTreeData() {
      this.$ajax.post('departmentResponsibleChild').then(res => {
        this.treeData = res.data
      })
    },
    // 获取部门人员
    loadTableData(id) {
      this.$ajax.post('queryFilter', {
        departmentId: id ? id : 0
      }).then(res => {
        res.data.forEach(item => {
          let inIs = this.staffListID.find(function(obj) {
            return obj == item.id
          })
          if (!inIs) {
            item.check = false
          }else {
            item.check = true
          }
        })
        this.tableData = res.data
      })
    },
    // 选择部门结构
    treeRowClick(data) {
      this.loadTableData(data.id)
    },
    // 勾选巡检员
    checked(row) {
      if (!this.staffListID.includes(row.id)) {
        this.checkedStaffList.push(row)
        this.staffListID.push(row.id)
      } else {
        this.checkedStaffList.forEach((item, index) => {
          if (item.id == row.id) {
            this.deleteStaffIds.push(item.id)
            this.checkedStaffList.splice(index, 1)
            this.$delete(this.staffListID, index)
          }
        })
      }
    },
    // 选择通知人员点击确定
    drawerOK() {
      this.drawer = false
      this.staffList = [].concat(this.checkedStaffList)
      this.staffListID = [].concat(this.staffListID)
    },
    async loadData() {
      this.loading = true
      this.$ajax.post('timeNoticeDetail',{},{},this.row.timerNoticeRuleId).then(res => {
        // if(res.data.receivers.length>0) {
        //   res.data.receivers.forEach(v=>{
        //     this.staffList.push({
        //       id: v.staffId,
        //       name: v.staffName
        //     })
        //   })
        // }
        // res.data.serious = res.data.notificationModeCodes
        let firstInput = ''
        let formulaList = [{
          tagName:'',
          tagDesc:null,
          textName:'',
          tagDrawer: false,
          tagFRadio: '',
        }]

        this.form.robotId = res.data.entWeChatRobotId
        this.form.robotName = res.data.entWeChatRobotName
        this.form.frequencyType = res.data.frequency?res.data.frequency.frequencyType:null
        this.form.dateTime = this.secondToDate(res.data.frequency?res.data.frequency.dateTime:null)
        this.form.statisticsType = res.data.dataStatistics?res.data.dataStatistics.statisticsType:null
        this.form.title = res.data.title
        this.form.content = res.data.content
        this.form.level = res.data.level?res.data.level:null
        this.form.noticeTypeCode = res.data.noticeTypeCode
        this.form.notificationModeCodes = res.data.notificationModeCodes

        if(res.data.equipmentTrigger) {
          let vTag = null
          res.data.equipmentTrigger.equipments.forEach(async v=>{
            if(v.triggerCondition.realData.formulaType=='formula'&&v.triggerCondition.realData.tag.indexOf('Tag') != -1) {
              vTag = await this.loadTags(v.eid,v.triggerCondition.realData.formula,'plc')
            }
            this.equipments.push({
              drawer: false,
              configRadio: v.eid,
              deviceDrawer: false,
              tagRadio: '',
              abilityOrPointTable: v.triggerCondition.realData.tag.indexOf('Tag') != -1 ? 1 : 0,
              parameterEn: v.triggerCondition.realData.tag,
              parameterCn: v.triggerCondition.realData.tag,
              pointName: v.triggerCondition.realData.tag,
              gatewayEid: v.gatewayEid,
              eid: v.eid,
              equipmentBrandCode: v.equipmentBrandCode,
              equipmentName: v.equipmentName,
              describe: v.describe,
              unit: v.unit,
              triggerCondition: {
                dataType:v.triggerCondition.dataType,
                boolData:v.triggerCondition.boolData,
                realData:{
                  tag:v.triggerCondition.realData.tag,
                  formulaType:v.triggerCondition.realData.formulaType,
                  firstInput: v.triggerCondition.realData.formulaType=='formula' ? vTag.firstInput : firstInput,
                  formulaList:v.triggerCondition.realData.formulaType=='formula' ? vTag.formulaList : formulaList
                }
              }
            })
          })
        }
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    secondToDate(val) {
      let s = val % 60
      let _s = val - s
      let m = (_s / 60) % 60
      let h = (_s / 60 - m) / 60
      return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}`
    },
    dateToSecond(str) {
      let strArr = str.split(':')
      let second =  (strArr[0] * 3600) + (strArr[1] * 60)
      return second
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let form = {
            timerNoticeRuleId: this.row.timerNoticeRuleId,
            title: this.form.title,
            content: this.form.content,
            noticeTypeCode: this.form.noticeTypeCode,
            // receiverIds: [],
            // level: this.form.level,
            notificationModeCodes: this.form.notificationModeCodes,
            equipmentTrigger: {
              equipments: [],
            },
            entWeChatRobotId: this.form.robotId?this.form.robotId:null,
            frequency: {
              frequencyType: this.form.frequencyType,
              dateTime: this.form.frequencyType&&this.form.frequencyType!='every-hour'?this.dateToSecond(this.form.dateTime):null
            },
            dataStatistics: {
              statisticsType: this.form.statisticsType
            },
          }
          // if(this.staffList.length==0&&this.form.noticeTypeCode!='equipment') {
          //   return this.$message.error('请选择通知人')
          // }

          if(this.form.notificationModeCodes.find(item=>item=='entWeChatRobot')=='entWeChatRobot') {
            if(!this.form.robotId) {
              return this.$message.error('请选择机器人')
            }
          }else {
            form.entWeChatRobotId = null
          }

          if(this.form.noticeTypeCode=='equipment') {
            let equipmentName = this.equipments.some(v=>!v.equipmentName)
            let tag = this.equipments.some(v=>!v.triggerCondition.realData.tag)
            let describe = this.equipments.some(v=>!v.describe)

            if(equipmentName) return this.$message.error('请选择设备')
            if(tag) return this.$message.error('请选择点表')
            if(describe) return this.$message.error('请输入点表描述')

            for(let i=0;i<this.equipments.length;i++) {
              let data = this.equipments[i].triggerCondition
              if(!data.dataType) return this.$message.error('请选择数据类型')
              if(data.dataType=='Real') {
                if(!data.realData.formulaType) return this.$message.error('请选择点表值类型')
                if(!data.realData.firstInput&&data.realData.formulaType=='formula') return this.$message.error('请配置公式')
              }
            }
            this.equipments.forEach(v=>{
              let formula = v.triggerCondition.realData.firstInput
              v.triggerCondition.realData.formulaList.forEach(f=>{
                formula += f.tagName+f.textName
              })
              form.equipmentTrigger.equipments.push({
                gatewayEid: v.gatewayEid,
                eid: v.eid,
                equipmentBrandCode: v.equipmentBrandCode,
                equipmentName: v.equipmentName,
                describe: v.describe,
                unit: v.unit,
                triggerCondition: {
                  dataType:v.triggerCondition.dataType,
                  boolData:v.triggerCondition.boolData,
                  realData:{
                    tag:v.triggerCondition.realData.tag,
                    formulaType:v.triggerCondition.realData.formulaType,
                    formula:v.triggerCondition.realData.formulaType=='formula'?formula:null
                  }
                }
              })
            })
          }else {
            form.equipmentTrigger = {}
            // this.staffList.forEach(v=>{
            //   form.receiverIds.push(v.id)
            // })
          }

          // console.log(form)
          this.loading = true;
          this.$ajax.post("timeNoticeUpdate", form).then((res) => {
            this.loading = false;
            this.$message.success("成功");
            this.$router.push("/receiveNotice");
          }).catch(err=>{
            this.loading = false;
          })
        }
      });
    },
    getTagVal(arr,val) {
      let a = arr.find((v)=>v.pointName==val)
      return a.describe
    },
    getAbilityVal(arr,val) {
      let a = arr.find((v)=>v.parameterEn==val)
      return a.parameterCn
    },
    formulaHandle(formula,res,flag) {
      let realData = {
        firstInput:'',
        formulaList:[]
      }
      let originalArr = formula.split(/([+-/\*])/)
      let fArr = originalArr.filter(v=>{
        let bArr = res.data.find((l)=>v==(flag=='plc'?l.pointName:null))
        return bArr
      })
      let firstStr = formula.slice(0,formula.indexOf(fArr[0]))
      let residueStr = formula.split(firstStr).join('')
      realData.firstInput = firstStr
      let arr = []
      fArr.forEach((v,k)=>{
        let str = ''
        if(residueStr.indexOf(v)!=-1&&residueStr.indexOf(fArr[k+1])!=-1) {
          if(residueStr.indexOf(fArr[k+1])!=0) {
            str = residueStr.slice(residueStr.indexOf(v),residueStr.indexOf(fArr[k+1]))
            str = str.split(v)
            str[0] = v
            str[1] = str[1]
          }else {
            str = residueStr.slice(residueStr.indexOf(v),residueStr.indexOf(fArr[k+1])-fArr[k+1].length)
            str = str.split(v)
            str[0] = v
            str[1] = str[1]
          }
        }else {
          str = residueStr.slice(residueStr.indexOf(v))
          str = str.split(v)
          str[0] = v
          str[1] = str[1]
        }
        arr.push(str)
      })
      arr.forEach(v=>{
        realData.formulaList.push({
          tagName:v[0],
          tagDesc:flag=='plc'?this.getTagVal(res.data,v[0]):null,
          textName:v[1],
          tagDrawer:false,
          tagFRadio:'',
        })
      })
      return realData
    },
    loadTags(eid,formula,flag) {
      return new Promise((resolve,reject)=>{
        this.$ajax.post("iotTagsQuery", {}, { eid }).then(res=>{
          let fResult = this.formulaHandle(formula,res,flag)
          resolve(fResult)
        })
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push("/receiveNotice");
    } else {
      this.row = this.$route.params.row
      this.loadData()
    }
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading {
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 1200px;
}
.el-input {
  width: 500px;
}
.el-textarea {
  width: 500px;
}
.el-radio {
  margin-right: 60px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.staffBox {
  cursor: pointer;
  width: 490px;
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #DCDFE6;
}
.notify-method {
  width: 650px;
  display: flex;
}
.info-tips {
  position: absolute;
  right: -20px;
  top: 13px;
  font-size: 16px;
  color: #9a9a9a;
  transition: all .3s ease;
  &:hover {
    color: #c1c1c1;
  }
}
</style>